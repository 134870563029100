import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"
import Header from "../../layouts/header4"
import Footer from "../../layouts/footer4"

import "../../css/custom-css/custom-products-services.css"

import Layout from "../../components/custom-components/Layout"
import BannerHeader from "../../components/custom-components/BannerHeader"

import bg from "../../images/services/banner-service.png"
import Seo from "../../components/seo"

const HPVGenotyping = () => {
  return (
    // <Layout>
    <div className="skin-1 index">
      <Seo title="HPV DNA Genotyping Test" />
      <Header servicesPage />

      <BannerHeader
        background={bg}
        title="HPV DNA Genotyping Test"
        servicesDetails
      />
      <div className="content-block">
        <div className="section-full content-inner-custom">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-4 col-md-5">
                <div>
                  <div className="icon-content">
                    <h2 className="dlab-title">Our Services</h2>
                  </div>
                </div>
                <div className="widget sidebar-widget ext-sidebar-menu widget_nav_menu">
                  <ul className="menu">
                    <li className="active">
                      <Link to="/services/hpv-genotyping">
                        HPV DNA Genotyping Test
                      </Link>
                    </li>
                    <li>
                      <Link to="/services/hpv-highrisk">
                        HPV DNA High Risk Test
                      </Link>
                    </li>
                    <li>
                      <Link to="/services/liquid-based-cytology">
                        Liquid Based Cytology
                      </Link>
                    </li>
                    <li>
                      <Link to="/services/co-testing">Co-Testing</Link>
                    </li>
                  </ul>
                </div>

                <div>
                  <div className="icon-content">
                    <h2 className="dlab-title">Our Products</h2>
                  </div>
                </div>
                <div className="widget sidebar-widget ext-sidebar-menu widget_nav_menu">
                  <ul className="menu">
                    <li>
                      <Link to="/products/hpv-xpress-matrix">
                        HPV XpressMatrix™ Kit
                      </Link>
                    </li>
                    <li>
                      <Link to="/products/sars-cov-2-real-time">
                        Elva Diagnostic SARS-CoV-2 Real-time RT PCR Kit
                      </Link>
                    </li>
                    <li>
                      <Link to="/products/sars-cov-2-saliva-nucleic">
                        Elva Diagnostic SARS-CoV-2 Saliva Nucleic Acid Test Kit
                      </Link>
                    </li>
                    <li>
                      <Link to="/products/ampfire-hpv-screening">
                        AmpFire HPV Screening HR 16/18 Kit
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-xl-9 col-lg-8 col-md-7 m-b30">
                <div className="row">
                  <div className="col-md-6 col-sm-12 mb-4">
                    <StaticImage
                      src="../../images/services/service-genotyping-1.png"
                      layout="fullWidth"
                    />
                  </div>
                  <div className="col-md-6 col-sm-12 mb-4">
                    <StaticImage
                      src="../../images/services/service-genotyping-2.png"
                      layout="fullWidth"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <h4 className="dlab-title mt-2">HPV DNA Genotyping Test</h4>
                    <p className="ml-4">
                      <ul>
                        <li>
                          Cervical cancer is caused by human papillomavirus
                          (HPV) infection.
                        </li>
                        <li>
                          The HPV test captures the known cancer causing
                          viruses.
                        </li>
                        <li>
                          Human papillomavirus (HPV) is the most commonly
                          transmitted sexual infection, and most sexually active
                          people—men and women—will get it.
                        </li>
                        <li>
                          Although the virus is spread sexually, its presence
                          doesn't mean that you've been promiscuous or
                          unfaithful. Nor does its presence mean you will get
                          cancer. In fact, most women have no symptoms, and the
                          virus goes away on its own.
                        </li>
                        <li>
                          In some women, the virus persists for years after the
                          initial infection, and it is at risk of eventually
                          developing cell changes that could lead to cancer.
                        </li>
                        <li>
                          HPV testing is more sensitive than the cytology test
                          for detecting precancer.
                        </li>
                        <li>
                          Our HPV DNA Genotyping test is specific detect of 21
                          HPV subtypes:
                          <ul className="ml-4">
                            <li>
                              15 High Risk subtype (oncogenic): HPV 16, 18, 31,
                              33, 35, 39, 45, 51,52, 53, 56, 58, 59, 66, 68.
                            </li>
                            <li>
                              6 Low Risk subtype : HPV 6, 11, 42, 43, 44, 81.
                            </li>
                          </ul>
                        </li>
                        <li>
                          Persistent infection with HPV is the principal cause
                          of cervical cancer and its precursor cervical
                          intraepithelial neoplasia (CIN).
                        </li>
                        <li>
                          Knowing the genotypes of HPV is important and
                          beneficial:
                          <ol className="ml-4">
                            <li>
                              Beware of persistent infections
                              <br />
                              {/* <p> */}
                              More than 90% HPV infections go away on their own
                              within 6 to 24 months. Persistent HPV infection is
                              critical risk factor of cervical carcinogenesis
                              (National Cancer Institute). Persistency can only
                              be recognized by genotyping when someone having
                              been infected with identical HPV subtypes in 2
                              consecutive years. This is the advantage of HPV
                              genotyping test over non-genotyping one.
                              {/* </p> */}
                            </li>
                            <li>
                              Different HPV subtypes leading to a different
                              management.
                              <br />
                              {/* <p> */}
                              Positive HPV subtypes 16 and 18 test result is
                              mandatory to colposcopy regardless of cytology
                              results. Non HPV 16 and 18 subtypes may be
                              observed for next 12 months.
                              {/* </p> */}
                            </li>
                          </ol>
                        </li>
                        <li>
                          The laboratory uses the HPV XpressMatrix™ Kit in its
                          testing which is a local production kit and has
                          registered from the Ministry of Health of Indonesia
                          and passed the proficiency test.
                        </li>
                      </ul>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer hideContactInfo />
    </div>
  )
}

export default HPVGenotyping
